import React from 'react';

const DataCommon = (data,metaSupplier)=>{
   
    let paxes = 0;
    let response = []
    if(Object.keys(data).length > 0){
        data.step[0].data.passengers.map((pax,i)=>{
       
            pax.adults.map((a,index)=>{
                paxes ++;
            })
        
            if(pax.child != null && Object.keys(pax.child).length > 0){
                pax.child.map((c,index)=>{
                    paxes ++;
                })
            }
            if(pax.infants != null && Object.keys(pax.infants).length > 0){
                pax.infants.map((i,index)=>{
                    paxes ++;
                })
            } 
        }) 
    }  
   
    response["module"]        = data.module
    response["logged_user"]   = data.userLogin
    response["sonata_client"] = data.client.nameCopy
    response["start_date"]    = data.step[0].data.date.checkin
    response["end_date"]      = data.step[0].data.date.checkout
    response["sonata_source"] = (metaSupplier !== "") ? metaSupplier.split("&")[0] : data.client.nameCopy; 
    response["q_pax"]         = paxes;
    
    return response

}

function dataVL(data, metaSupplier){
    let dataCommon = DataCommon(data,metaSupplier)
    let cabin = []
    window.getProduct = () => {
        window.myvar = dataCommon
        window.myvar["trip_type"]          = (data.products.pivot[0].cardDetail.cabotage) ? 'Nacional' : 'Internacional' 

        window.myvar["trip_route"] = data.products.pivot[0].cardDetail.name
        window.myvar["service_provider_code"] = data.products.pivot[0].cardDetail.validateCarrier
        window.myvar["service_provider_name"] = data.products.pivot[0].cardDetail.validateCarrierName
        cabin["NA"] = "Todas";
        cabin["Y"] = "Económica";
        cabin["W"] = "Premium";
        cabin["C"] = "Business";
        cabin["F"] = "Primera Clase";

        window.myvar["cabin"]                 = cabin[data.products.pivot[0].cardDetail.cabin]
        window.myvar["origin_airport"]        = data.products.pivot[0].cardDetail.originAirport
        window.myvar["destination_airport"]   = data.products.pivot[0].cardDetail.destinationAirport
        if(data.products.pivot[0].cardDetail.secondLine.includes("Multidestino"))
            window.myvar["itinerary_type"] = "Multidestination"

        if(data.products.pivot[0].cardDetail.secondLine.includes("Ida y Vuelta"))
            window.myvar["itinerary_type"] = "roundtrip"

        if(data.products.pivot[0].cardDetail.secondLine.includes("Solo Ida"))
            window.myvar["itinerary_type"] = "oneway"

        let originCity         = []
        let originCountry      = []
        let destinationCity    = []
        let destinationCountry = []

        data.products.pivot[0].cardDetail.detailItems.map((tramo,i)=>{     
          
            tramo.details.detailSearch.map((detail,h)=>{
               
                if(detail["title"].includes("Origen:")){
                    
                    let origin = detail["description"].split(",")
                    originCity.push(origin[0])
                    originCountry.push(origin[1])
                }

                if(detail["title"].includes("Destino:")){
                    let destination = detail["description"].split(",")
                    destinationCity.push(destination[0])
                    destinationCountry.push(destination[1])
                }
            })
        })

        window.myvar["origin_city"]         = originCity.join(",").replace(".","")
        window.myvar["origin_country"]      = originCountry.join(",").replace(".","")
        window.myvar["destination_city"]    = destinationCity.join(",").replace(".","")
        window.myvar["destination_country"] = destinationCountry.join(",").replace(".","")

        return window.myvar
    } 
}

function dataHT(data, metaSupplier){
   
    let dataCommon = DataCommon(data,metaSupplier)
    window.getProduct = () => {
        
        window.myvar = dataCommon
        window.myvar["hotel_name"]         = data.products.pivot[0].cardDetail.name
        window.myvar["destination_city"]   = data.products.pivot[0].cardDetail.destination.split(",")[0]
        window.myvar["destination_country"]= data.products.pivot[0].cardDetail.destination.split(",")[1]
        
        window.myvar["trip_type"]          = (data.country === data.products.pivot[0].cardDetail.countryCode) ? 'Nacional' : 'Internacional' 
        
        let roomsName = []
        let category = 0
        data.products.pivot[0].cardDetail.detailItems.map((item,i)=>{
            item.details.detailProduct.map((room,h)=>{
                if(room["title"] === "Categoría:")
                    category = room["description"]
                
                if(room["title"].includes("Habitación:"))
                    roomsName.push(room["description"])
            })
        })
        window.myvar["hotel_category"] = category
        window.myvar["hotel_rooms"]    = roomsName
        return window.myvar;
        
    }
}

function dataAT(data, metaSupplier){
    
    let dataCommon = DataCommon(data,metaSupplier)
    window.getProduct = () => {
        window.myvar = dataCommon
        let origin       = ''
        let destination  = ''
        let pickup  = ''
        let pickup_time  = ''
        let dropoff  = ''
        let dropoff_time  = ''
       
        data.products.pivot[0].cardDetail.detailItems.map((car,i)=>{
            car.details.detailSearch.map((detail,h)=>{
                if(detail["title"] === "Lugar de retiro:"){
                    origin = detail["description"].split(",");
                    window.myvar["origin_airport"] = origin[0]
                    window.myvar["trip_type"] = (origin[1] === data.products.pivot[0].cardDetail.countryCode) ? 'Nacional' : 'Internacional' 
                    window.myvar["origin_city"] = origin[2]
                    window.myvar["origin_country"] = origin[3]
                }

                if(detail["title"] === "Fecha de retiro:"){
                    pickup = detail["description"].split(",")
                    pickup_time = pickup[1].split(" ")
                    window.myvar["pickup_time"] = pickup_time[3]
                }

                if(detail["title"] === "Lugar de entrega:"){
                    destination = detail["description"].split(",");
                    window.myvar["destination_airport"] = destination[0]
                    window.myvar["destination_city"] = destination[2]
                    window.myvar["destination_country"] = destination[3]
                }

                if(detail["title"] === "Fecha de entrega:"){
                    dropoff = detail["description"].split(",")
                    dropoff_time = dropoff[1].split(" ")
                    window.myvar["dropoff_time"] = dropoff_time[3]
                }
            })
        })
        
        window.myvar["car_rental"] = data.products.pivot[0].cardDetail.provider
        window.myvar["car_model"] = data.products.pivot[0].cardDetail.name
        
        return window.myvar
    }
}

function dataAS(data, metaSupplier){
    
    let dataCommon = DataCommon(data,metaSupplier)
    window.getProduct = () => {
        window.myvar = dataCommon
        let secondLine = data.products.pivot[0].cardDetail.secondLine.split(",")
        let q_days     =  secondLine[0].split(" ")
        window.myvar["q_days"] = q_days[0]
        window.myvar["insurance_product"] = data.products.pivot[0].cardDetail.name
        window.myvar["service_provider"] = data.products.pivot[0].cardDetail.provider
        return window.myvar
    }
}

export const GetProductPrebooking = (data,metaSupplier) => {

    
    switch (data.module) {
        case 'flights': 
            dataVL(data,metaSupplier)
        break;
        case 'hotels':
            dataHT(data,metaSupplier)
        break;
        case 'cars':
            dataAT(data,metaSupplier)
        break;
        case "assists":
            dataAS(data,metaSupplier)
        break;
    
        default:
            break;
    }
}

export const GetProductPayment = (payment, data)=>{
   
    switch (payment) {
        case 'offline':
            window.getProduct = () => {
                window.myvar["payment_type"] = payment
                return window.myvar
            }
        break;
    
        default:
            window.getProduct = () => {
                window.myvar["payment_type"] = payment
                window.myvar["card_bank"] = data.paymentFee.bank_name
                window.myvar["credit_card_code"] = data.paymentFee.credit_card_code
                window.myvar["credit_card_name"] = data.paymentFee.credit_card_name
                window.myvar["installments"] = data.paymentFee.payment_fees
                window.myvar["total_amount"] = data.paymentFee.total_amount
                window.myvar["financial_cost"] = data.paymentFee.interest_amount
                window.myvar["fee_amount"] = data.paymentFee.charge_amount
                let tax = []
              
                data.paymentFee.purchaseDetail.priceDetail[0].charge.map((item,i)=>{
                    if(item["title"] === "Impuesto PAIS")
                        tax["Impuesto PAIS"] = item["valor"]

                    if(item["title"] === "Imp. y Tasas")
                        tax["Imp. y Tasas"] = item["valor"]
                    
                    if(item["title"] === "Percepcion Afip RG 4815/2020")
                        tax["Percepcion Afip RG 4815/2020"] = item["valor"]
                    
                    if(item["title"] === "Cargos")
                        tax["Cargos"] = item["valor"]
                })
                    
                window.myvar["tax_amount"] = tax
               
              
                return window.myvar
            }
        break;
    }
  
}

export const GetProductThankyouPage = (dataFinal)=>{
    window.getProduct = () => {
        window.myvar["service_id"] = dataFinal.step.data.idTransaction
        return window.myvar
    }
}

export const GetProductCrossell = (amount)=>{
    window.getProduct = () => {
        window.myvar["cross_sell_amount"] =amount
        return window.myvar
    }
}



