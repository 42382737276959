import axios from 'axios';
import config from "../config/config.json";
import { sendEventOnUnloadSuccess, sendEventOnUnloadFailure } from "../actions/CartActionV2";

export const sendEventOnUnloadService = async (email, firstName, phoneNumber, data) => {
    console.log('llega al service')
    console.log('datos en el service: ', email, firstName, phoneNumber, data)

    let pathArray = window.location.pathname.substr(1).split('/');
    let module = pathArray[0];

    let fechaIda, fechaSalida, destino;
    const url = data.timer.redirect

    if (config.client.modules[module].automation !== undefined && config.client.modules[module].automation) {
        switch (module) {
            case 'assists':
                fechaIda = data.step[0].data.date.checkin;
                fechaSalida = data.step[0].data.date.checkout
                destino = data.products.pivot[0].data.zone
                break;

            case 'flights':
                fechaIda = data.step[0].data.items[0].cardProducts[0].cardDetail.dateIn
                fechaSalida = data.step[0].data.items[0].cardProducts[0].cardDetail.dateOut
                destino = data.products.pivot[0].cardDetail.name
                break;

            case 'cars':
                fechaIda = data.step[0].data.date.checkin
                fechaSalida = data.step[0].data.date.checkout
                destino = data.products.pivot[0].data.prebookingResponse.result.getData.pickUpAddress
                break;

            case 'hotels':
                fechaIda = data.products.pivot[0].data.prebookingResponse.data.infobook.checkin
                fechaSalida = data.products.pivot[0].data.prebookingResponse.data.infobook.checkout
                destino = data.products.pivot[0].data.prebookingResponse.data.infobook.destination
                break;
            default:
                return;
        };
        try {
            const response = await axios.post('https://track.embluemail.com/contacts/event', {
                email,
                eventName: 'carrito_abandonado',
                attributes: {
                    searchUrl: url,
                    nombre: firstName,
                    destino: destino,
                    fechaInicio: fechaIda,
                    fechaFin: fechaSalida,
                    telefono: phoneNumber,
                    event_items: [
                        {
                        }
                    ]
                }
            }, {
                headers: {
                    'Authorization': 'Basic YTUzOGZhZTdjNzIwNDFhNmI3N2Q5NjkxNmM4NzI3Y2M=',
                    'Content-Type': 'application/json',
                }
            });

            console.log('Respuesta de la API:', response.data);

            // Despachar la acción de éxito
            sendEventOnUnloadSuccess(response.data);

            return response.data;
        } catch (error) {
            console.error('Error en la llamada fetch:', error);

            // Despachar la acción de error
            sendEventOnUnloadFailure(error);

            throw error;
        }
    } else {
        console.log('error')
        return;
    }
};