
import React, { Component, Suspense, Fragment } from 'react'
import PreloadSection from "./layout/preloads/PreloadSection";
import PreloadBooking from "./layout/preloads/PreloadBooking";
import Header from "./Header";
import FooterFinalView from "./FooterFinalView";
import Theme from "../assets/Theme";
import config from ".././config/config.json";
import Metas from "./Metas";
import { DlPurchase, DlPurchaseError } from '../services/DatalayerGeneral';

class ThankYouPage extends Component {
    /* constructor(props) {
        super(props)
        console.log(props)
    } */

    getStatusTemplate(containerModule, dataFinal, prebooking, ip) {
        let folderCountry = containerModule.charAt(0).toUpperCase() + containerModule.slice(1);
        let Component = React.lazy(() => import(`./layout/thankYouPage/${folderCountry}`));

        return (
            <Suspense fallback={<PreloadSection />}>
                <Component
                    dataFinal={dataFinal}
                    prebooking={prebooking}
                    client={config.client}
                    styles={config.styles}
                    ip={ip}
                    module={dataFinal.module.toLowerCase()}
                />
            </Suspense>
        )
    }

    render() {
        console.log("old thanyoupage", this.props);
        let dataFinal = '';
        let prebooking = '';
        let nameContainer = '';
        let userLogin = '';
        let ip = '';
        if (!this.props.preload) {

            dataFinal = this.props.location.state.dataFinal
            prebooking = this.props.location.state.prebooking
            userLogin = this.props.location.state.userLogin
            nameContainer = this.props.location.state.dataFinal.step.nameContainer
            ip = this.props.location.state.ip
        }

        const stylesConfig = config.styles
        //console.log(this.props)
        return (
            <Fragment>

                <Metas client={config.client} module={dataFinal.module.toLowerCase()} />

                <Theme>
                    <div className="wrapper" styles={stylesConfig}>
                        <Header client={config.client} userLogin={userLogin} />
                        <div className="main">
                            {(this.props.location.state.preload) ? (
                                <PreloadBooking />
                            ) : (
                                <section className={`thankyoupage ${nameContainer}`}>
                                    {this.getStatusTemplate(nameContainer, dataFinal, prebooking, ip)}
                                </section>
                            )}
                        </div>
                        <FooterFinalView client={config.client} />
                    </div>
                </Theme>
            </Fragment>
        )
    }
}

export default ThankYouPage