import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Paper, Typography, Icon } from '@material-ui/core'
import PopoverSimpleTitleContent from "./layout/PopoverSimpleTitleContent";
import DialogTimer from './layout/DialogTimer';
import { sendEventOnUnloadAction } from '../actions/CartActionV2';


class Timer extends Component {
    state = {
            minutes: this.props.timer.value,
            seconds: 0,
        };
    

    componentDidMount() {
        console.log('props cuando se monta el timer', this.props)
        this.myInterval = setInterval(async () => {
            const { seconds, minutes } = this.state
            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                    const { email, firstName, phoneNumber, data } = this.props.automationData;
                    console.log(this.props)
                    try {
                        sendEventOnUnloadAction(email, firstName, phoneNumber, data);
                    } catch (error) {
                        console.error('Error al enviar evento en unload:', error);
                    }

                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    getMessages = (timer) => {
        if (timer.messages) {
            const messages = timer.messages
            return (
                <Fragment>
                    {messages.map((message, x) =>
                        <Fragment key={x}>
                            {message.items.map((item, x) =>
                                <Fragment key={x}>
                                    <span className="icon_right">
                                        <PopoverSimpleTitleContent
                                            className="info_timer"
                                            name="info_timer"
                                            title={item.title}
                                            content={item.description}
                                            icon="info_outline"
                                        />
                                    </span>
                                </Fragment>
                            )}
                        </Fragment>
                    )}</Fragment>
            )
        }
    }
    
    render() {
        const { /* totalMinutes,  */minutes, seconds } = this.state
        const { layoutTimer, timer, expired, module } = this.props;

        return (
            <Fragment>{layoutTimer[module] &&
                <Paper className={`paper timer ${layoutTimer.template}`}>
                    <span className="icon_left"><Icon>access_time</Icon></span>
                    {this.getMessages(timer)}
                    <div>
                        {/* {console.log("prueba :", this.state.minutes)} */}
                        {(minutes === 0 && seconds === 0) || expired
                            ? <DialogTimer redirect={timer.redirect} info={timer.infoModal} />
                            : <div className="text"><Typography variant="body2"><span className="inner-text">La reserva caducará en: </span><span className="time">{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span></Typography></div>
                        }
                    </div>
                </Paper>
            }</Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        automationData: {
            email: state.cartReducerV2.email,
            firstName: state.cartReducerV2.firstName,
            phoneNumber: state.cartReducerV2.phoneNumber,
            data: state.layoutReducerV2.prebooking,
            x: state.cartReducerV2
        },
    };
};

export default connect(mapStateToProps)(Timer);