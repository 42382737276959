import React, { Fragment, Suspense, Component } from 'react';
import { Stepper, Step, StepLabel, Button, Grid, Container, Icon, Hidden } from '@material-ui/core';
import ContainerFixesTop from "./ContainerFixesTop";
import SecureSite from "./layout/SecureSite";
import ContainerSticky from 'react-sticky-el';
import ContainerFixedMobile from "react-headroom"
import { connect } from "react-redux";
import { getInfoStepAction, updatePreloadAction } from "../actions/StepAction";

import DetailDialog from "./layout/purchase/DetailDialog";
import PreloadStep from "./layout/preloads/PreloadStep";
/* import { Redirect,Link as RouterLink } from "react-router-dom"; */
import TimerV2 from './TimerV2';
import Copyright from './layout/Copyright';
import PreloadStepFull from "./layout/preloads/PreloadStepFull";
import { animateScroll as scroll } from 'react-scroll'
import config from "./../config/config.json";
import Metas from "./Metas";
import Maintitle from "./Maintitle";
import { GetMessagesTopSidebar } from './CardDetailMessages'
import { DlCheckoutBegin, DlCheckoutSteps, DlExtrasAsistencia } from '../services/DatalayerGeneral'

class Steps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setActiveStep: (this.props.activeStep) ? this.props.activeStep : 0,
            validate: false,
            crossell: false,
            addToCart: {},
            purchaseDetail: {},
            data: {},
            selectedData: {},
            bank: {},
            creditCard: {},
            paymentFee: {},
            dataStep: [],
            triggerDlCheckoutBegin: false,
            triggerDlCheckoutSteps: true
        }
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            purchaseDetail: this.props.purchaseDetail,
            addToCart: this.props.purchaseDetail.priceDetail,
            triggerDlCheckoutBegin: true
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevState) {
        // cuando hay un campio en las propiedades se ejecuta esta funcion
        this.setState({ bank: nextProps.bank, creditCard: nextProps.creditCard, paymentFee: nextProps.paymentFee, selectedData: nextProps.selectedData, purchaseDetail: nextProps.purchase, addToCart: nextProps.addToCart, data: nextProps.dataStep });

        // if (this.state.setActiveStep !== prevState.setActiveStep) {
        //     console.log('%c ---- CAMBIO DE ESTADO', 'color: #E65412; background: #fdeee7');
        //     this.setState({ triggerDlCheckoutSteps: true });
        // }
    }

    getStepContent(containerModule, data, purchaseDetail, products) {

        data = this.state.data;

        if (this.state.crossell) {
            let Component = React.lazy(() => import(`./layout/crossell/Crossell`));
            //let value = "valor";

            return <Suspense fallback={<PreloadStep />}>
                <Component ref={element => { this.component = element }} selectedData={this.props.selectedData} selected={this.props.selected} purchaseDetail={this.props.purchaseDetail} data={this.props.products.crossell} conditionsUrl={config.client.conditionsUrl} />            </Suspense>
        } else {
            let Component = React.lazy(() => import(`./scenes/${containerModule}`));

            return <Suspense fallback={<PreloadStep />}>
                <Component
                    ref={element => { this.component = element }}
                    module={this.props.module}
                    hash={this.props.hash}
                    preload={this.props.preload}
                    allowFormMethodPayment={this.props.allowFormMethodPayment}
                    allowForm={this.props.allowForm}
                    creditCard={this.state.creditCard}
                    bank={this.state.bank}
                    paymentFee={this.state.paymentFee}
                    data={data}
                    purchaseDetail={purchaseDetail}
                    products={products}
                    conditionsUrl={config.client.conditionsUrl}
                    paymentSelected={this.props.paymentSelected} />
            </Suspense>
        }
    }

    handleNext() {
        console.log('Passenger', this.component)
        const responseChilComponent = this.component.handleSubmit();
        //const nextStepAction = '';
        if (responseChilComponent.continue) {
            if (!this.state.crossell && Object.keys(this.props.products.crossell).length > 0 && this.state.setActiveStep === 0) {
                this.setState({
                    crossell: true
                })
                this.nextStepAction = this.props.steps[this.state.setActiveStep].action;

            } else {
                if (this.state.setActiveStep === this.props.steps.length - 1) {
                    return this.props.getFinalStep(responseChilComponent.data);
                } else {
                    this.setState({ crossell: false })
                    this.setState({ setActiveStep: this.state.setActiveStep + 1 })
                    this.nextStepAction = this.props.steps[this.state.setActiveStep + 1].action;
                    if (!responseChilComponent.data.crossell) {
                        responseChilComponent.data['crossell'] = []
                        responseChilComponent.data['crossellSelected'] = []
                    }
                }
            }

            this.props.dispatchGetInfoStepAction(this.props.ip, this.props.hash, this.nextStepAction, this.state.addToCart, responseChilComponent.data, this.props.dataStep);
            this.props.updatePreloadAction(false);
            this.setState(prevState => ({ triggerDlCheckoutBegin: false }));
            scroll.scrollToTop();
        }
    }

    handleBack() { this.setState({ setActiveStep: this.state.setActiveStep - 1 }) }

    handleReset() { this.setState({ setActiveStep: 0 }) }

    datalayer() {
        console.log('%c ---- FUNC DATALAYER', 'color: #E65412; background: #fdeee7', this.state);

        if (this.state.setActiveStep == 0 && this.state.triggerDlCheckoutBegin == true && this.props.preload == true) {
            DlCheckoutSteps(
                "addPassengerInfo",
                this.props.module,
                null,
                null);
        }
        if (this.state.setActiveStep == 1 && this.state.purchaseDetail.title != undefined && this.props.preload == true) {
            DlCheckoutSteps(
                "addPaymentInfo",
                this.props.module,
                null,
                null);
        }
        if (this.state.setActiveStep == 2 && this.props.preload == true) {
            DlCheckoutSteps(
                "confirmationPage",
                this.props.module,
                null,
                null);
        }



    }

    render() {
        const activeStep = this.state.setActiveStep;
        const module = this.props.module.toLowerCase();
        //const dictionary = config.client.dictionary   
        //const stepNumber = this.state.setActiveStep + 1;


        const stepNumber = (this.state.setActiveStep === 0) ? this.state.setActiveStep + 1 : this.state.setActiveStep;

        {
            (!this.state.triggerDlCheckoutBegin && this.state.setActiveStep === 0) &&
                DlCheckoutBegin(
                    "checkoutBegin",
                    this.props.module,
                    this.props.products.pivot[0].data,
                    this.props.selectedData);
        }
        this.datalayer();

        return (
            <Fragment>
                <Metas client={config.client} module={module} />

                <div className={`steps ${(!this.props.preload) ? 'preload-open' : ''}`}>

                    {/* {this.props.preloadCurrent[`step${stepNumber}`]} */}

                    {(!this.props.preload) && <PreloadStepFull preload={this.props.preloadCurrent[`step${stepNumber}`]} />}

                    {/* STEPS ICONS */}
                    <section className="stepper_container">
                        <Stepper activeStep={activeStep} className="stepper_component">
                            {this.props.steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label.name}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </section>
                    {/* ENDS STEPS ICONS */}

                    {/* STEPS CONTENT */}

                    {/* PURCHASE DETAIL MOBILE */}
                    <Hidden mdUp>
                        {/* <Sticky scrollElement=".scrollareaMdDown"></Sticky> */}
                        <ContainerFixedMobile>
                            <div className="sticky-inner">
                                <ContainerFixesTop
                                    layout={this.props.layout}
                                    installment={this.state.paymentFee}
                                    selectedData={this.state.selectedData}
                                    addToCartPurchase={this.state.addToCart}
                                    purchaseDetail={this.props.purchaseDetail}
                                    timer={this.props.timer}
                                    expired={this.props.expired}
                                    products={this.props.products}
                                    module={module}
                                />
                            </div>
                        </ContainerFixedMobile>
                    </Hidden>
                    {/* END PURCHASE DETAIL MOBILE */}

                    <Container className="container_steps">
                        <Grid container spacing={2} >
                            <Grid item md={8} className="col_content">
                                <div className="steps_container">
                                    {activeStep === this.props.steps.length ? (
                                        <Fragment>
                                            Vista final - OK
                                            <Button onClick={() => this.handleReset()}>Inicio</Button>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {this.props.layout.timer.template === 'timerTopContent' && (
                                                <Hidden smDown>
                                                    <TimerV2
                                                        layoutTimer={this.props.layout.timer}
                                                        timer={this.props.timer}
                                                        expired={this.props.expired}
                                                        module={module}
                                                    />
                                                </Hidden>
                                            )}

                                            {activeStep === 0 &&
                                                <Maintitle client={config.client} module={module} />
                                            }

                                            {this.getStepContent(
                                                this.props.steps[activeStep].nameContainer,
                                                this.props.steps[activeStep].data,
                                                this.props.purchaseDetail,
                                                this.props.products,
                                                this.props.data.passengers
                                            )}
                                        </Fragment>
                                    )}
                                    <Hidden smDown>
                                        <div className="col_content_copyright">
                                            <Copyright copyright={config.client.copyright} footerLinks={config.client.footerLinks} />
                                        </div>
                                    </Hidden>
                                </div>

                            </Grid>
                            <Hidden smDown>
                                {/* LATERAL DESKTOP */}
                                <Grid item md={4} className="col_sidebar">
                                    <ContainerSticky>
                                        <ContainerFixesTop
                                            addToCartPurchase={this.state.addToCart}
                                            purchaseDetail={this.props.purchaseDetail}
                                            timer={this.props.timer}
                                            expired={this.props.expired}
                                            products={this.props.products}
                                            selectedData={this.state.selectedData}
                                            installment={this.state.paymentFee}
                                            layout={this.props.layout}
                                            module={module}
                                        />
                                    </ContainerSticky>
                                    <GetMessagesTopSidebar messages={this.props.data.messages} />
                                    <SecureSite client={config.client} />
                                </Grid>
                                {/* END LATERAL DESKTOP */}
                            </Hidden>
                        </Grid>

                    </Container>
                    {/* ENDS STEPS CONTENT */}

                    <div className="steps_buttons">
                        <Container>
                            {activeStep === this.props.steps.length ? (
                                <Button onClick={() => this.handleReset()}>Inicio</Button>
                            ) : (
                                <div className="steps_buttons_inner">
                                    <span className="text_total_sumary">
                                        {Object.keys(this.state.paymentFee).length !== 0 ? (
                                            <Fragment>
                                                <span className="text">Total a pagar </span>
                                                <span className="price">{this.props.purchaseDetail.currency}<span className="amount">{this.state.paymentFee.purchaseDetail.totalAmount}</span></span>
                                                <span className="price">
                                                    {this.state.paymentFee.purchaseDetail.installmentAmount &&
                                                        <Fragment>({this.state.paymentFee.purchaseDetail.installmentAmount})</Fragment>
                                                    }</span>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <span className="text">Total a pagar </span>
                                                <span className="price">{this.props.purchaseDetail.currency}<span className="amount">{this.props.purchaseDetail.totalAmount}</span></span>
                                            </Fragment>
                                        )}

                                        <DetailDialog icon="info_outlined" installment={this.state.paymentFee} selectedData={this.state.selectedData} purchaseDetail={this.props.purchaseDetail} products={this.props.products} />
                                    </span>

                                    <Button
                                        className="btn_next_step" id="continue" color="primary" variant="contained" onClick={() => this.handleNext()} endIcon={<Icon>arrow_forward</Icon>}>
                                        {activeStep === this.props.steps.length - 1 ? 'Pagar' : 'Continuar'}
                                    </Button>
                                </div>
                            )}
                        </Container>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.stepReducer;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetInfoStepAction: (ip, hash, nextStepAction, addToCart, currentDataStep, lastDataStep) =>
            dispatch(
                getInfoStepAction(
                    ip, hash, nextStepAction, addToCart, currentDataStep, lastDataStep
                ),
            ),
        updatePreloadAction: (boolean) => {
            dispatch(updatePreloadAction(boolean))
        }
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Steps);



