import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid, Container } from '@material-ui/core'
import RawMarkup from './layout/RawMarkup'


export default function FooterFinalView(props) {
    const {client} = props
    return (
        <footer className="footer-final-view">
            <Container>
                    <Grid container className="copyright">
                       {/*  {client.copyright.map((item, i) => <Typography key={i} component="p">{item.item}</Typography>)}  */}                       
                        {client.copyright.map((item, i) => <RawMarkup key={i} content={item.item} />)}                   
                    </Grid>
                </Container>
        </footer>
    )
}
