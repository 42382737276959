import { sendEventOnUnloadService } from "../services/cartServiceV2.js";
import config from "../config/config.json";

export const updateEmail = (newEmail) => ({
    type: 'UPDATE_EMAIL_V2',
    payload: newEmail,
});

export const updateFirstName = (newFirstName) => ({
    type: 'UPDATE_FIRST_NAME_V2',
    payload: newFirstName,
});

export const sendEventOnUnloadSuccess = (data) => {
    return {
        type: 'SEND_AUTOMATION_EMAIL_SUCCESS_V2',
        payload: data,
    };
};

export const sendEventOnUnloadFailure = (error) => ({
    type: 'SEND_AUTOMATION_EMAIL_FAILURE_V2',
    payload: error,
});

export const sendEventOnUnloadAction = (email, firstName, phoneNumber, data) => {
    console.log('llega a las actions: ', email, firstName, phoneNumber, data)
    sendEventOnUnloadService(email, firstName, phoneNumber, data)
}

export const updateData = (newData) => ({
    type: 'UPDATE_DATA_V2',
    payload: newData,
});

export const updateNumber = (newNumber) => ({
    type: 'UPDATE_NUMBER_V2',
    payload: newNumber,
});