import { combineReducers } from "redux";
import layoutReducer from "./LayoutReducer";
import layoutReducerV2 from "./LayoutReducerV2";
import stepReducer from "./StepReducer";
import stepReducerV2 from "./StepReducerV2";
import cartReducer from "./CartReducer"
import cartReducerV2 from "./CartReducerV2"

export default combineReducers({
  layoutReducer,
  layoutReducerV2,
  stepReducer,
  stepReducerV2,
  cartReducer,
  cartReducerV2
});
