const INITIAL_STATE = {
    email: '',
    firstName: 'cliente',
    fechaSalida: '',
    fechaVuelta: '',
    searchUrl: '',
    data: {}
};

export default (state = INITIAL_STATE, action) => {
    console.log('action', action)
    switch (action.type) {
        case 'UPDATE_EMAIL':
            console.log('PAYLOAD en update EMAIL', action.payload)

            return {
                ...state,
                email: action.payload,
            };
        case 'UPDATE_FIRST_NAME':
            console.log('PAYLOAD en update FIRSTNAME', action.payload)
            return {
                ...state,
                firstName: action.payload,
            };
        case 'SEND_AUTOMATION_EMAIL_SUCCESS':
            console.log('success case en reducer', action.payload)
            return {
                ...state,
                automationEmailData: action.payload,
                automationEmailError: null,
            };
        case 'SEND_AUTOMATION_EMAIL_FAILURE':
            return {
                ...state,
                automationEmailData: null,
                automationEmailError: action.payload,
            };
        case 'UPDATE_DATA':
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};