import React, { Component, Fragment } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import FooterFinalView from "./FooterFinalView";
import StepsV2 from "./StepsV2";
import Preload from "./layout/preloads/Preload";
import PreloadBooking from "./layout/preloads/PreloadBooking";
import { connect } from "react-redux";
import { getPrebookingAction, getBookingAction, updatePreloadLayoutAction } from "../actions/LayoutActionV2";
import Theme from "../assets/Theme";
import config from "../config/config.json";
//import data from "./../demo-request/flights_v2/getPrebooking_crossell.json"; // Para test
import { Redirect } from "react-router-dom";
import { GetProductPrebooking } from '../services/GetProductsV2'

class LayoutV2 extends Component {

    constructor(props) {
        super(props);
        /* console.log(this.props) */

        this.state = {
            hash: '',
            "finalStepPreload": false,
        }
        window.myvar = []

        this.handlerFinalStep = this.handlerFinalStep.bind(this);
    }

    componentDidMount() {
        const hash = this.props.match.params.hash;
        // Actualiza module de localStorage
        this.setState({ hash: hash, prebooking: this.props.prebooking });
        this.props.dispatchGetPrebookingAction(hash);
    }

    handlerFinalStep(dataStep, prevStep) {
        //console.log("handlerFinalStep layout");
        this.props.updatePreloadLayoutAction(true);
        this.props.dispatchGetBookingAction(this.props.ip, this.state.hash, dataStep, prevStep);
        this.setState({ "finalStepPreload": true })

    }

    render() {
        const prebooking = this.props.prebooking

        const allowFinalStep = this.props.allowFinalStep;
        const render = this.props.startRender;
        const avalability = (this.props.prebooking.hasOwnProperty('error')) ? true : false;

        if (!avalability) {
            GetProductPrebooking(this.props.prebooking, this.props.location.search)
        }

        const client = config.client

        const stylesConfig = config.styles

        const CurrentPreload = () => {
            if (this.props.prebooking.preloads !== undefined &&
                this.props.prebooking.preloads.step3 !== undefined) {
                return (<Preload preload={this.props.prebooking.preloads.step3} />)
            } else {
                return (<Preload preload="" />)
            }
        }

        return (

            <Fragment>
                {!this.props.preload ? (
                    (!render) ?
                        <Redirect to={config.defaultErrorRoute} exact />
                        :
                        (avalability) ?
                            <Redirect to={this.props.prebooking.step.path} />
                            :
                            (!this.props.prebooking.styles) ?
                                <Redirect to={{
                                    pathname: `${this.props.prebooking.data.path}${this.props.prebooking.data.idTransaction}${this.props.location.search}`,
                                    state: {
                                        dataFinal: this.props.prebooking,
                                        prebooking: this.props.prebooking,
                                        preload: this.props.preload,
                                        userLogin: this.props.userLogin,
                                        ip: this.props.ip
                                    }
                                }} exact />
                                :
                                <Theme>
                                    <div className={`wrapper`} styles={stylesConfig} >
                                        <Header client={client} userLogin={this.props.userLogin} />
                                        <div className="main">
                                            {(allowFinalStep) ?
                                                <Fragment>
                                                    {(this.props.dataFinal === false) ?
                                                        <Redirect to={config.defaultErrorRoute} exact />
                                                        :
                                                        <Redirect to={{
                                                            pathname: `${this.props.dataFinal.data.path}${this.props.dataFinal.data.idTransaction}${this.props.location.search}`,
                                                            state: {
                                                                dataFinal: this.props.dataFinal,
                                                                prebooking: prebooking,
                                                                preload: this.props.preload,
                                                                userLogin: this.props.userLogin,
                                                                ip: this.props.ip
                                                            }
                                                        }} exact />
                                                    }
                                                </Fragment>
                                                :
                                                <StepsV2
                                                    getFinalStep={this.handlerFinalStep}
                                                    steps={prebooking.step}
                                                    client={client}
                                                    purchaseDetail={prebooking.purchaseDetail}
                                                    timer={prebooking.timer}
                                                    products={prebooking.products}
                                                    hash={this.props.hash}
                                                    expired={prebooking.expired}
                                                    data={prebooking.step[0]}
                                                    layout={client.layout}
                                                    //module={prebooking.step[0].data.module}
                                                    preloadCurrent={prebooking.preloads}
                                                    ip={this.props.ip}
                                                    module={this.props.match.params.module}
                                                />
                                            }
                                        </div>

                                        {(allowFinalStep) ? (<FooterFinalView client={config.client} />)
                                            : (<Footer client={config.client} />)}

                                    </div>
                                </Theme>
                ) : (
                    <Fragment>
                        {this.state.finalStepPreload ? (
                            <PreloadBooking preload={this.props.prebooking.preloads.step3} />
                        ) : (<CurrentPreload />)}
                    </Fragment>
                )
                }
            </Fragment>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.layoutReducerV2;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetPrebookingAction: (hash) =>
            dispatch(
                getPrebookingAction(
                    hash
                ),
            ),
        dispatchGetBookingAction: (ip, hash, dataStep, prevStep) =>
            dispatch(
                getBookingAction(
                    ip,
                    hash,
                    dataStep,
                    prevStep

                )
            ),
        updatePreloadLayoutAction: (boolean) => {
            dispatch(updatePreloadLayoutAction(boolean))
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LayoutV2);
